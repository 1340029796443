import React, { useEffect, useState } from "react";
import Api from "../../services/Api";
import Client from "../../services/Client";
import statIcon from "../../asessts/Images/stat_icon.svg";
import product from "../../asessts/Images/noOrder.svg";
import Loading from "../../common/Loading/Loading";
import action from "../../asessts/Images/action_eye.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../../redux/slices/currentPageSlice";

function CompletedOrder() {
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState("");
  const [perPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentPage = useSelector(
    (state) => state.currentPageSlice.currentPage
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const fetchBlog = async () => {
    try {
      setIsLoading(true);
      const response = await Api.get_complete_data();
      console.log("response", response);
      setPosts(response.data.response.trackOrderListing);
      setTotal(response.data.response.total);
      setIsLoading(false);
    } catch (error) {
      console.log("Error while fetching posts", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch(setCurrentPage(newPage));
      setIsLoading(true);
      try {
        const response = await Client.get(
          `/app/admin/orders/getCompletedOrders?page=${newPage}`
        );
        setPosts(response.data.response.trackOrderListing);
        setIsLoading(false);
      } catch (error) {
        console.log("Error while fetching posts", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div class="table-data-main">
        <div class="table-data">
          <table border="0" cellpadding="0" style={{ width: "100%" }}>
            <thead
              style={{
                backgroundColor: "#FDFDFD",
                borderBottom: "1px solid #EDEDED",
                borderTop: "1px solid #ededed",
              }}
            >
              <tr>
                <th class="heading">Order ID</th>
                <th class="heading">Product</th>
                <th class="heading">Customer</th>
                <th class="heading">Price</th>
                <th class="heading">Completed On</th>
                <th class="heading">Action</th>
              </tr>
            </thead>
            {isLoading ? (
              <Loading />
            ) : (
              <tbody>
                {posts.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center no_data-image">
                      <img
                        style={{ padding: "120px" }}
                        src={product}
                        alt="no_data_image"
                      />
                    </td>
                  </tr>
                ) : (
                  posts.map((order, index) => (
                    <tr
                      key={index}
                      style={{
                        borderBottom: "1px solid #EDEDED",
                        backgroundColor:
                          order?.status === "rejected"
                            ? "#ffdddd"
                            : "transparent",
                      }}
                    >
                      <td class="table-col">
                        <table>
                          <tr>
                            <td class="rating" style={{ border: "none" }}>
                              {order.orderShortId}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style={{ padding: "25px" }}>
                        <table>
                          <tr>
                            <td class="product-img" style={{ border: "none" }}>
                              <img
                                src={order.dressDetails.images[0]}
                                alt="location"
                              />
                            </td>
                            <td
                              class="rating"
                              style={{
                                border: "none",
                                padding: "0 20px",
                              }}
                            >
                              {order.dressDetails.name}
                              <p>
                                <img src={statIcon} alt="" />{" "}
                                {order.dressDetails.ratings}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td class="table-col">{order.customer}</td>
                      <td class="table-col">€{order.price}</td>

                      <td class="table-col">{formatDate(order.completedOn)}</td>
                      <td class="table-col">
                        <Link to={`/complete-order-details/${order?.orderId}`}>
                          <img src={action} alt="" />
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {posts?.length > 0 && (
        <div className="next-previous-slide">
          <div className="slides-heading">
            <span>
              Showing {(currentPage - 1) * perPage + 1} to{" "}
              {Math.min(currentPage * perPage, total)} out of {total}
            </span>
          </div>

          <div className="slides-button">
            <ul className="d-flex justify-center align-items-center  p-0 m-0">
              <li>
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ cursor: "pointer", color: "#333" }}
                  ></i>
                </button>
              </li>
              <li className="d-flex justify-center align-items-center">
                {Array.from({ length: Math.ceil(total / perPage) }).map(
                  (_, index) => {
                    // Add logic to display ellipsis (...) if there are many pages
                    if (
                      index < 2 ||
                      index > Math.ceil(total / perPage) - 3 ||
                      (index >= currentPage - 2 && index <= currentPage + 1)
                    ) {
                      return (
                        <button
                          key={index + 1}
                          onClick={() => handlePageChange(index + 1)}
                          className={
                            currentPage === index + 1 ? "active-slide" : ""
                          }
                        >
                          {index + 1}
                        </button>
                      );
                    } else if (
                      index === 2 ||
                      index === Math.ceil(total / perPage) - 3
                    ) {
                      // Display ellipsis (...) for the third and third-to-last position
                      return <span key={index}>...</span>;
                    }
                    return null;
                  }
                )}
              </li>
              <li>
                <button
                  disabled={currentPage === Math.ceil(total / perPage)}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  ></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default CompletedOrder;
