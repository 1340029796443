import React, { useEffect, useRef, useState } from "react";
import bar from "../../asessts/Images/bar_icon.svg";
import logo from "../../asessts/Images/bar_icon.svg";
import mobile_Logo from "../../asessts/Images/dashboard_logo.png";
// import profile from "../../asessts/Images/profile_user.png";
import user from "../../asessts/Images/profile_user_icon.svg";
import logoutImg from "../../asessts/Images/logout_icon.svg";
import client from "../../services/Client";
import { useDispatch, useSelector } from "react-redux";
import { setAdminData } from "../../redux/slices/LoginSlice";
import { Link, useNavigate } from "react-router-dom";
import LogoutModal from "../Modal/LogoutModal";
import { logout } from "../../redux/slices/LogoutSlice";

function Header({ title, imageUrl }) {
  const dropdownRef = useRef(null);
  const adminDataOne = useSelector((state) => state.adminSlice.adminData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const storedCreds = JSON.parse(localStorage.getItem("creds")) || {};
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
    setOverlay(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setOverlay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleImageClick = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    // localStorage.removeItem("creds");
    // localStorage.removeItem("fusionId");
    dispatch(logout());
    document.body.style.overflow = "hidden";
    setLogoutModalOpen(true);
    setIsDropdownOpen(false);
  };

  const onConfirmLogout = () => {
    document.body.style.overflow = "auto";
    // setIsLoading(true);
    localStorage.removeItem("creds");
    localStorage.removeItem("admin");
    localStorage.removeItem("fusionId");
    navigate("/login");
    setLogoutModalOpen(false);
    // setIsLoading(false);
  };

  const cancelLogout = () => {
    document.body.style.overflow = "auto";
    setLogoutModalOpen(false); // Close the modal
  };

  const fetchAdmin = async () => {
    try {
      const response = await client.get(
        `/app/admin/settings/get-admin-details/${storedCreds.id}`
      );
      const adminData = response?.data?.admin;
      dispatch(setAdminData(adminData));
    } catch (error) {
      console.log("Error fetching admin", error);
    }
  };
  useEffect(() => {
    fetchAdmin();
  }, []);
  // console.log("Admin data", adminDataOne);

  return (
    <div class="dashboard-header">
      <div class="bar-btn-mobile">
        <span>
          <img src={bar} alt="" />
        </span>
      </div>
      <div className="dashboard-heading d-flex">
        {imageUrl && (
          <img
            style={{ cursor: "pointer" }}
            src={imageUrl}
            alt="Back"
            onClick={handleImageClick}
          />
        )}
        <h2 className="ms-2">{title}</h2>
      </div>
      <div class="logo-mobile">
        <img src={mobile_Logo} alt="" />
      </div>
      <div class="profile-picture">
        <div class="profile-img" ref={dropdownRef}>
          {adminDataOne ? (
            <img
              src={adminDataOne?.profileImage}
              alt=""
              onClick={toggleDropdown}
            />
          ) : null}
          {isDropdownOpen && (
            <div className="profile-dropdown">
              <div className="profile-info">
                <div className="profile-image">
                  <img src={adminDataOne.profileImage} alt="" />
                </div>
                <h3>
                  {adminDataOne.firstName} {adminDataOne.lastName}
                </h3>
                <span>{adminDataOne.email}</span>
              </div>
              <div className="log-out">
                <ul className="p-0">
                  <li>
                    <Link to="/setting" className="profile-btn">
                      <img src={user} alt="logout" /> My Profile
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleLogout} className="logout-btn active">
                      <img src={logoutImg} alt="" />
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      {logoutModalOpen && <div className="overlay"></div>}
      {overlay && <div className="overlay"></div>}
      <LogoutModal
        isOpen={logoutModalOpen}
        onClose={cancelLogout}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want logout?"
        onConfirmLogout={onConfirmLogout}
      />
    </div>
  );
}

export default Header;
