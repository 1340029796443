import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import action from "../../asessts/Images/action_eye.svg";
import product from "../../asessts/Images/lender-empty.png";
import stat from "../../asessts/Images/stat_icon.svg";
import client from "../../services/Client";
import Loading from "../../common/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setCurrentPage } from "../../redux/slices/currentPageSlice";

function LenderList() {
  const [lenders, setLenders] = useState();
  const [total, setTotal] = useState("");
  const [perPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage = useSelector(
    (state) => state.currentPageSlice.currentPage
  );

  const fetchPosts = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(`/app/admin/lender/getAlllenders`);

      // console.log("____", response);

      setLenders(response?.data?.response?.lender);
      setTotal(response.data.response.total);
      // dispatch(setCurrentPage(response.data.response.currentPage));
      setIsLoading(false);
      // setIsPostLoading(false);
    } catch (error) {
      console.log("Error while fetching posts", error);
      setIsLoading(false);
      // setIsPostLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch(setCurrentPage(newPage));
      setIsLoading(true);
      try {
        const response = await client.get(
          `/app/admin/lender/getAlllenders?page=${newPage}`
        );
        setLenders(response?.data?.response?.lender);
        navigate(`/lenders?page=${newPage}`);
        setIsLoading(false);
      } catch (error) {
        console.log("Error while fetching posts", error);
        setIsLoading(false);
      }
    }
  };

  const getInitials = (name) => {
    const nameArray = name.split(" ");
    return nameArray
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header title={"Lenders"} />
        <div class="loaction-list-main">
          <div class="table-data-main">
            <div class="table-data table-data-two">
              <table border="0" cellPadding="0" style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    borderBottom: "1px solid #EDEDED",
                    borderTop: "1px solid #ededed",
                  }}>
                  <tr>
                    <th class="heading">Name</th>
                    <th class="heading">Email</th>
                    <th class="heading">No. of items listed</th>
                    <th class="heading">Status</th>
                    <th class="heading" style={{ width: "7%" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <tbody>
                      {lenders?.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center no_data-image">
                            <img
                              style={{ padding: "120px" }}
                              src={product}
                              alt="no_data_image"
                            />
                          </td>
                        </tr>
                      ) : (
                        lenders?.map((lender, index) => (
                          <tr style={{ borderBottom: "1px solid #EDEDED" }}>
                            <td style={{ padding: "20px 25px 21px" }}>
                              <table>
                                <tr>
                                  <td
                                    class="product-img"
                                    style={{ border: "none" }}>
                                    {lender.image !== "" ? (
                                      <img src={lender.image} alt="agent" />
                                    ) : (
                                      <div className="placeholder-name">
                                        {getInitials(lender.name)}
                                      </div>
                                    )}
                                  </td>
                                  <td
                                    class="rating"
                                    style={{
                                      border: "none",
                                      padding: "0 20px",
                                    }}>
                                    {lender.name}
                                    <p>
                                      <img src={stat} alt="" />{" "}
                                      {lender?.ratings?.toFixed(1)}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td class="table-col">{lender.email}</td>
                            <td class="table-col">{lender.NumOfLendedItems}</td>
                            <td class="table-col">
                              <button className="order_track-button">
                                {lender.status
                                  ? lender.status
                                  : "Not Available"}
                              </button>
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              class="table-col">
                              <button class="action-btn">
                                <Link to={`/lender-details/${lender.id}`}>
                                  <img src={action} alt="" />
                                </Link>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
        </div>
        {lenders?.length > 0 && (
          <div className="next-previous-slide">
            <div className="slides-heading">
              <span>
                Showing {(currentPage - 1) * perPage + 1} to{" "}
                {Math.min(currentPage * perPage, total)} out of {total}
              </span>
            </div>

            <div className="slides-button">
              <ul className="d-flex justify-center align-items-center  p-0 m-0">
                <li>
                  <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <i
                      className="fa fa-angle-left"
                      aria-hidden="true"
                      style={{ cursor: "pointer", color: "#333" }}></i>
                  </button>
                </li>
                <li className="d-flex justify-center align-items-center gap-2">
                  {Array.from({ length: Math.ceil(total / perPage) }).map(
                    (_, index) => {
                      // Add logic to display ellipsis (...) if there are many pages
                      if (
                        index < 2 ||
                        index > Math.ceil(total / perPage) - 3 ||
                        (index >= currentPage - 2 && index <= currentPage + 1)
                      ) {
                        return (
                          <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={
                              currentPage === index + 1 ? "active-slide" : ""
                            }>
                            {index + 1}
                          </button>
                        );
                      } else if (
                        index === 2 ||
                        index === Math.ceil(total / perPage) - 3
                      ) {
                        // Display ellipsis (...) for the third and third-to-last position
                        return <span key={index}>...</span>;
                      }
                      return null;
                    }
                  )}
                </li>
                <li>
                  <button
                    disabled={currentPage === Math.ceil(total / perPage)}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <i
                      className="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LenderList;
