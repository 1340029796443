import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBar: false,
};

const sideBarSlice = createSlice({
  name: "sideBarSlice",
  initialState,
  reducers: {
    setSideBar: (state, action) => {
      state.sideBar = action.payload;
    },
  },
});

export const { setSideBar } = sideBarSlice.actions;
export default sideBarSlice.reducer;
