import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import "./asessts/style/style.css";
import "./asessts/style/responsive.css";
import VerifyOtp from "./pages/auth/VerifyOtp";
import Dashboard from "./pages/dashboard/Dashboard";
import Order from "./pages/order/Order";
import DisputedList from "./pages/order/DisputedList";
import Setting from "./pages/setting/Setting";
import MobileHome from "./pages/mobileHome/MobileHome";
import BorrowerList from "./pages/borrowers/BorrowerList";
import BorrowerDetail from "./pages/borrowers/BorrowerDetail";
import TrackOrderDetails from "./pages/order/TrackOrderDetails";
import DisputeDetail from "./pages/order/DisputeDetail";
import CompleteDetail from "./pages/order/CompleteDetail";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import LenderList from "./pages/lenders/LenderList";
import LenderDetail from "./pages/lenders/lenderDetails";
import ShopNow from "./pages/shop/ShopNow";

function PrivateRoute({ element }) {
  const isLoggedIn = localStorage.getItem("fusionId");

  return isLoggedIn ? element : <Navigate to="/" />;
}

function App() {
  const isLoggedIn = localStorage.getItem("fusionId");
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          c
          <Route path="/order" element={<PrivateRoute element={<Order />} />} />
          <Route
            path="/disputed-order"
            element={<PrivateRoute element={<DisputedList />} />}
          />
          <Route
            path="/order-details/:id"
            element={<PrivateRoute element={<TrackOrderDetails />} />}
          />
          <Route
            path="/dispute-details/:id"
            element={<PrivateRoute element={<DisputeDetail />} />}
          />
          <Route
            path="/complete-order-details/:id"
            element={<PrivateRoute element={<CompleteDetail />} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} />}
          />
          <Route
            path="/setting"
            element={<PrivateRoute element={<Setting />} />}
          />
          <Route
            path="/shop-now"
            element={<PrivateRoute element={<ShopNow />} />}
          />
          <Route
            path="/mobile-home"
            element={<PrivateRoute element={<MobileHome />} />}
          />
          <Route
            path="/borrower/*"
            element={<PrivateRoute element={<BorrowerList />} />}
          />
          <Route
            path="/borrower-details/:id"
            element={<PrivateRoute element={<BorrowerDetail />} />}
          />
          <Route
            path="/lenders/*"
            element={<PrivateRoute element={<LenderList />} />}
          />
          <Route
            path="/lender-details/:id"
            element={<PrivateRoute element={<LenderDetail />} />}
          />
        </Routes>
      </BrowserRouter>
      <Toaster reverseOrder={true} />
    </div>
  );
}

export default App;
