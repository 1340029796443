import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import action from "../../asessts/Images/arrow_left.svg";
import TrackIcon from "../../asessts/Images/track_right_icon.svg";
import Api from "../../services/Api";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading/Loading";

function TrackOrderDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [dispatchedPhotos, setDispatchedPhotos] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [trackNumber, setTrackNumber] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");

  const formatStatus = (status) => {
    return status
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const response = await Api.get_track_order(id);
      console.log("response data", response);
      const trackOrderListing = response?.data?.response || [];
      setOrderDetails(trackOrderListing);
      const lastItem = trackOrderListing[trackOrderListing.length - 1];
      const status = lastItem?.status;

      switch (status) {
        case "dispached-by-lender":
          setDispatchedPhotos(
            lastItem?.dispatchedByLenderDetail?.dispatchPhotos || []
          );
          setOrderNumber(lastItem?.dispatchedByLenderDetail?.orderId);
          setTrackingUrl(lastItem?.dispatchedByLenderDetail?.trackUrl);
          setTrackNumber(lastItem?.dispatchedByLenderDetail?.trackNumber);
          break;
        case "received-by-borrower":
          setDispatchedPhotos(
            lastItem?.recievedByBorrowerDetail?.receivedOrderPhotos || []
          );
          setOrderNumber(lastItem?.recievedByBorrowerDetail?.orderId);
          setTrackingUrl(lastItem?.recievedByBorrowerDetail?.trackUrl);
          setTrackNumber(lastItem?.recievedByBorrowerDetail?.trackNumber);
          break;
        case "In Transit":
          setDispatchedPhotos(
            lastItem?.intransiteByLender?.intransiteImages || []
          );
          setOrderNumber(lastItem?.intransiteByLender?.orderId);
          setTrackingUrl(lastItem?.intransiteByLender?.trackUrl);
          setTrackNumber(lastItem?.intransiteByLender?.trackNumber);
          break;
        case "return-by-borrower":
          setDispatchedPhotos(
            lastItem?.returnBackByBorrower?.returnBackPhotos || []
          );
          setOrderNumber(lastItem?.returnBackByBorrower?.orderId);
          setTrackingUrl(lastItem?.returnBackByBorrower?.trackUrl);
          setTrackNumber(lastItem?.returnBackByBorrower?.trackNumber);
          break;
        // case "accepted-by-lender":
        //   setDispatchedPhotos(
        //     lastItem?.disputeByLenderDetail?.disputedPhotos || []
        //   );
        //   setOrderNumber(lastItem?.disputeByLenderDetail?.orderId);
        //   setTrackingUrl(lastItem?.disputeByLenderDetail?.trackUrl);
        //   setTrackNumber(lastItem?.disputeByLenderDetail?.trackNumber);
        //   setResponseMessage(
        //     lastItem?.dispatchedByLenderDetail?.reasonOfDispute
        //   );
        // break;
        default:
          setDispatchedPhotos([]);
          break;
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Set to true for 12-hour format with AM/PM
    };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header imageUrl={action} title={`Order details`} />
        <div class="loaction-list-main order-list-main">
          {isLoading ? (
            <Loading />
          ) : (
            <div class="order-tracking-main">
              <div class="tracking-left">
                <div class="head">
                  <h3>Order Tracking Details</h3>
                </div>
                <div class="details">
                  <div class="input-group">
                    {orderNumber && (
                      <div class="input-field">
                        <label for="">Order ID</label>
                        <input
                          type="text"
                          value={orderNumber}
                          placeholder={"#542365"}
                        />
                      </div>
                    )}
                    {trackNumber && (
                      <div class="input-field">
                        <label for="">Tracking Number</label>
                        <input
                          type="text"
                          value={trackNumber}
                          placeholder="#542365"
                        />
                      </div>
                    )}
                    {trackingUrl && (
                      <div class="input-field">
                        <label for="">Tracking URL</label>
                        <input
                          type="text"
                          value={trackingUrl}
                          placeholder="#542365"
                        />
                      </div>
                    )}
                  </div>
                  {dispatchedPhotos.length > 0 && (
                    <div class="order-photos">
                      <label for="">Product Dispatched Photos</label>
                      <div class="img">
                        {dispatchedPhotos.map((photo, index) => (
                          <span key={index}>
                            <img
                              className="py-3"
                              src={photo}
                              alt={`Dispatched ${index}`}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div class="tracking-right">
                <h3>Order Tracking Details</h3>
                <div class="return-back">
                  <div class="head">
                    <h4>
                      {orderDetails.length > 0
                        ? formatStatus(
                            orderDetails[orderDetails.length - 1].status
                          )
                        : ""}
                    </h4>
                  </div>
                  <div class="track-path">
                    <div class="order-track-path">
                      {orderDetails.map((order, index) => (
                        <div className="key-chain" key={index}>
                          <div className="key-chain-img">
                            <span>
                              <img src={TrackIcon} alt="" />
                            </span>
                          </div>
                          <div className="key-chain-head">
                            <h3>{formatStatus(order.status)}</h3>
                            <p>{formatDate(order.createdAt)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default TrackOrderDetails;
