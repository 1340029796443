import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Header from "../../common/Header/Header";
import client from "../../services/Client";
import editIcon from "../../asessts/Images/setting_edit_icon.svg";
import placeHolderIcon from "../../asessts/Images/PlaceHolder.svg";
import Loading from "../../common/Loading/Loading";
import toast from "react-hot-toast";
import Api from "../../services/Api";
import { MdDeleteForever } from "react-icons/md";

const MobileHome = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [occasions, setOccasions] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [pictures, setPictures] = useState([]);
  const fileInputRef = useRef(null);

  const [editBanner, setEditBanner] = useState({
    id: "",
    bannerImage: [],
    content: "",
  });

  const fetchAdmin = async () => {
    setIsLoading(true);
    try {
      const response = await client.get(
        `/app/admin/getBannerById/65e053d897d5124dee7c8114`
      );
      const bannerData = response.data.response;
      console.log("bannerData", bannerData);
      setEditBanner(() => ({
        id: bannerData.id,
        content: bannerData.content,
      }));
      const loadImages = bannerData?.bannerImage;

      if (loadImages && loadImages.length > 0) {
        setPictures(loadImages.map((url) => ({ url })));
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching banner data ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOccasion = async () => {
    setIsLoading(true);
    try {
      const response = await Api.get_occasion_data();
      const fetchedOccasions = response?.data?.response;
      const initialSelectedOccasions = fetchedOccasions.map((occasion) => ({
        id: occasion.id,
        status: occasion.isDisplayOnHomePage,
      }));
      setOccasions(fetchedOccasions);
      setSelectedOccasions(initialSelectedOccasions);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching admin", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmin();
    fetchOccasion();
  }, []);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    const newPictures = files.map((file) => ({
      url: URL.createObjectURL(file),
      file: file,
    }));
    if (newPictures.length + pictures.length <= 5) {
      setPictures((prevPictures) => [...prevPictures, ...newPictures]);
    } else {
      toast.error("You can only upload a maximum of 5 images");
    }
  };

  const handleDelete = (index) => {
    setPictures((prevPictures) => prevPictures.filter((_, i) => i !== index));
  };

  console.log("pictures", pictures);

  const updateAdminData = async (e) => {
    e.preventDefault();
    try {
      setIsBannerLoading(true);
      const formData = new FormData();

      for (const key in editBanner) {
        formData.append(key, editBanner[key]);
      }
      pictures?.forEach((img) => {
        if (img.file != null) {
          formData.append("bannerImage", img.file);
        } else {
          formData.append("bannerImage", img.url);
        }
      });
      await Api.updateBanner(formData);
      toast.success("Successfully updated Banner");
      setIsBannerLoading(false);
    } catch (error) {
      console.error("Error updating admin data:", error);
      toast.error("Failed to update banner. Please try again.");
      setIsBannerLoading(false);
    }
  };

  const handleCheckboxToggle = (event, occasionId) => {
    setSelectedOccasions((prevSelected) => {
      return prevSelected.map((occasion) => {
        if (occasion.id === occasionId) {
          return { ...occasion, status: !occasion.status };
        } else {
          return occasion;
        }
      });
    });
  };

  const updateOccasion = async (e) => {
    e.preventDefault();
    try {
      setIsBannerLoading(true);
      const updatedOccasions = selectedOccasions.map((occasion) => ({
        occasionId: occasion.id,
        display: occasion.status,
      }));

      const data = {
        ocasionDetails: updatedOccasions,
      };

      console.log("Setting", data);

      await Api.update_occasion_data(data);
      toast.success("Successfully updated Occasions");
      setIsBannerLoading(false);
    } catch (error) {
      console.error("Error updating occasions:", error);
      toast.error("Failed to update Occasions. Please try again.");
      setIsBannerLoading(false);
    }
  };

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header title={"Mobile Home"} />
        {isLoading ? (
          <Loading />
        ) : (
          <div class="loaction-list-main mobile-list-main">
            <div class="setting-main mobile-home-main">
              <div class="setting-details">
                <h3>Banner Image and Content</h3>
                <div class="basic-detail">
                  <div class="profile-details">
                    <div class="input-group">
                      <div class="input-field">
                        <label>Content</label>
                        <textarea
                          placeholder="Happy Christmas!"
                          value={editBanner.content}
                          onChange={(e) =>
                            setEditBanner({
                              ...editBanner,
                              content: e.target.value,
                            })
                          }></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-upload banner-image-new">
                  {pictures.length > 0 && (
                    <div className="profile-img">
                      {pictures?.map((picture, index) => (
                        <div key={index} className="relative">
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                            src={picture.url}
                            alt={`Profile ${index + 1}`}
                          />
                          <button
                            type="button"
                            onClick={() => handleDelete(index)}
                            className="">
                            <MdDeleteForever
                              style={{ color: "red", fontSize: "25px" }}
                            />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  {pictures.length < 5 && (
                    <div className="upload-img-mobile" onClick={handleDivClick}>
                      <button>
                        <img src={placeHolderIcon} alt="placeholder_icon" />
                      </button>
                      <input
                        type="file"
                        onChange={handleChange}
                        multiple
                        accept="image/*"
                        ref={fileInputRef}
                        className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <div
                  class="save-btn"
                  onClick={updateAdminData}
                  disabled={isBannerLoading}>
                  <button>
                    {isBannerLoading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
              <div class="add-area-info">
                <h3>List of Occasions</h3>
                <div class="streent-areas-select">
                  <div class="street-area-left">
                    {occasions.map((occasion) => (
                      <div class="street-label" key={occasion.id}>
                        <input
                          type="checkbox"
                          checked={
                            selectedOccasions.find((o) => o.id === occasion.id)
                              ?.status || false
                          }
                          onChange={(e) => handleCheckboxToggle(e, occasion.id)}
                        />
                        <label>{occasion.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  class="add-area-btn"
                  onClick={updateOccasion}
                  disabled={isBannerLoading}>
                  <button>
                    {isBannerLoading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileHome;
