import React, { useState, useEffect } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import action from "../../asessts/Images/arrow_left.svg";
import TrackIcon from "../../asessts/Images/track_right_icon.svg";
import { useParams } from "react-router-dom";
import Api from "../../services/Api";
import StarRatings from "react-star-ratings";
import Loading from "../../common/Loading/Loading";

function CompleteDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [reviews, setReviews] = useState({});
  const [feedback, setFeedback] = useState("");
  const [reason, setReason] = useState("");
  const [allPhotos, setAllPhotos] = useState([]);

  const OrderDetails = async () => {
    try {
      setIsLoading(true);
      const response = await Api.get_complete_order(id);
      const trackOrderListing =
        response?.data?.response?.trackOrderListing || [];
      setOrderDetails(response?.data?.response?.trackOrderListing);
      setReviews(response?.data?.response?.reviews);
      const lastItem = trackOrderListing[trackOrderListing.length - 1];
      const status = lastItem?.status;

      switch (status) {
        case "accepted-by-lender":
          setAllPhotos(
            lastItem?.acceptedReturnedDressDetails?.receivedDressPhotos || []
          );
          break;
        case "refund-by-admin":
          const disputeByLenderDetail = trackOrderListing.find(
            (item) => item.status === "dispute-by-lender"
          )?.disputeByLenderDetail;
          const disputeByBorrowerDetail = trackOrderListing.find(
            (item) => item.status === "dispute-by-borrower"
          )?.disputeByBorrowerDetail;
          setFeedback(lastItem?.refundByAdminDetails?.reasonOfRefund);

          // if (disputeByLenderDetail) {
          //   setAllPhotos(disputeByLenderDetail.disputedPhotos);
          // } else if (disputeByBorrowerDetail) {
          //   setAllPhotos(disputeByBorrowerDetail.disputedPhotos);
          // }
          break;
        case "request-rejected":
          setReason(lastItem?.rejectedDetail?.reason);
          break;
        default:
          setAllPhotos([]);
          break;
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching video details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    OrderDetails();
  }, [id]);

  console.log("reson", reason);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Set to true for 12-hour format with AM/PM
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatStatus = (status) => {
    return status
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-dashboard subadmin-dashboard">
        <Header imageUrl={action} title={`Order details`} />
        <div className="loaction-list-main order-list-main">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="order-tracking-main">
              <div className="tracking-left">
                <div className="head">
                  <h3>Order Tracking Details</h3>
                </div>
                <div className="details">
                  {allPhotos.length > 0 && (
                    <div className="order-photos">
                      <label htmlFor="">Product Dispatched Photos</label>
                      <div className="img">
                        {allPhotos.map((photo, index) => (
                          <img
                            style={{
                              width: "200px",
                              height: "200px",
                              objectFit: "cover",
                            }}
                            className="py-3"
                            key={index}
                            src={photo}
                            alt={`order ${index}`}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="feedback">
                    {reviews?.lender?.comments && (
                      <div style={{ width: "100%" }}>
                        <p>Lender's Feedback</p>
                        <StarRatings
                          rating={reviews?.lender?.ratings}
                          starRatedColor="gold"
                          starDimension="20px"
                          starSpacing="2px"
                        />
                        <div class="input-field">
                          <textarea
                            value={reviews?.lender?.comments}></textarea>
                        </div>
                      </div>
                    )}
                    {reviews?.borrower?.comments && (
                      <div style={{ width: "100%" }}>
                        <p>Borrower's Feedback</p>
                        <StarRatings
                          rating={reviews?.borrower?.ratings}
                          starRatedColor="gold"
                          starDimension="20px"
                          starSpacing="2px"
                        />
                        <div class="input-field">
                          <textarea
                            value={reviews?.borrower?.comments}></textarea>
                        </div>
                      </div>
                    )}
                    {feedback && (
                      <div style={{ width: "100%" }}>
                        <p>Admin's Feedback</p>
                        <div class="input-field">
                          <textarea value={feedback}></textarea>
                        </div>
                      </div>
                    )}
                    {reason && (
                      <div style={{ width: "100%" }}>
                        <p>Rejected Reason</p>
                        <div class="input-field">
                          <textarea value={reason}></textarea>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="tracking-right">
                <h3>Order Tracking Details</h3>
                <div className="return-back">
                  <div className="head">
                    <h4>{reason ? "Request Rejected" : "Order Completed"}</h4>
                  </div>
                  <div className="track-path">
                    <div className="order-track-path">
                      {orderDetails.map((order, index) => (
                        <div className="key-chain" key={index}>
                          <div className="key-chain-img">
                            <span>
                              <img src={TrackIcon} alt="" />
                            </span>
                          </div>
                          <div className="key-chain-head">
                            <h3>{formatStatus(order.status)}</h3>
                            <p>{formatDate(order.createdAt)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompleteDetail;
