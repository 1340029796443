import React, { useEffect, useState } from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Header from "../../common/Header/Header";
import statIcon from "../../asessts/Images/stat_icon.svg";
import action from "../../asessts/Images/action_eye.svg";
import client from "../../services/Client";
import product from "../../asessts/Images/borrower-empty.png";
import Loading from "../../common/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setCurrentPage } from "../../redux/slices/currentPageSlice";

function BorrowerList() {
  const [borrowers, setBorrowers] = useState();
  const [total, setTotal] = useState("");
  const [perPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage = useSelector(
    (state) => state.currentPageSlice.currentPage
  );
  // console.log("borrowers?.length", borrowers?.length);

  const fetchPosts = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(`/app/admin/borrower/getAllBorrower`);

      setBorrowers(response?.data?.response?.borrower);
      setTotal(response.data.response.total);
      // dispatch(setCurrentPage(response.data.response.currentPage));
      setIsLoading(false);
      // setIsPostLoading(false);
    } catch (error) {
      console.log("Error while fetching posts", error);
      setIsLoading(false);
      // setIsPostLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(total / perPage)) {
      dispatch(setCurrentPage(newPage));
      setIsLoading(true);
      try {
        const response = await client.get(
          `/app/admin/borrower/getAllBorrower?page=${newPage}`
        );
        setBorrowers(response?.data?.response?.borrower);
        navigate(`/borrower?page=${newPage}`);
        setIsLoading(false);
      } catch (error) {
        console.log("Error while fetching posts", error);
        setIsLoading(false);
      }
    }
  };

  const getInitials = (name) => {
    const nameArray = name.split(" ");
    return nameArray
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header title={"Borrowers"} />
        <div class="loaction-list-main">
          <div class="table-data-main">
            <div class="table-data table-data-two">
              <table border="0" cellPadding="0" style={{ width: "100" }}>
                <thead
                  style={{
                    backgroundColor: "#FDFDFD",
                    borderBottom: "1px solid #EDEDED",
                    borderTop: "1px solid #ededed",
                  }}>
                  <tr>
                    <th class="heading">Name</th>
                    <th class="heading">Email</th>
                    <th class="heading">No. of items borrowed</th>
                    <th class="heading" style={{ width: "7%" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <Loading />
                ) : (
                  <tbody>
                    {borrowers?.length === 0 ? (
                      <tr>
                        <td colSpan="4" className="text-center no_data-image">
                          <img
                            style={{ padding: "120px" }}
                            src={product}
                            alt="no_data_image"
                          />
                        </td>
                      </tr>
                    ) : (
                      borrowers?.map((borrower, index) => (
                        <tr style={{ borderBottom: "1px solid #EDEDED" }}>
                          <td style={{ padding: "20px 25px 21px" }}>
                            <table>
                              <tr>
                                <td
                                  class="product-img"
                                  style={{ border: "none" }}>
                                  {borrower.image !== "" ? (
                                    <img src={borrower.image} alt="agent" />
                                  ) : (
                                    <div className="placeholder-name">
                                      {getInitials(borrower.name)}
                                    </div>
                                  )}
                                </td>
                                <td
                                  class="rating"
                                  style={{ border: "none", padding: "0 20px" }}>
                                  {borrower.name}
                                  <p>
                                    <img src={statIcon} alt="" />{" "}
                                    {borrower?.ratings?.toFixed(1)}
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td class="table-col">{borrower.email}</td>
                          <td class="table-col">{borrower.numOfBorrowItems}</td>
                          <td style={{ textAlign: "center" }} class="table-col">
                            <button class="action-btn">
                              <Link to={`/borrower-details/${borrower.id}`}>
                                <img src={action} alt="" />
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {borrowers?.length > 0 && (
          <div className="next-previous-slide">
            <div className="slides-heading">
              <span>
                Showing {(currentPage - 1) * perPage + 1} to{" "}
                {Math.min(currentPage * perPage, total)} out of {total}
              </span>
            </div>

            <div className="slides-button">
              <ul className="d-flex justify-center align-items-center  p-0 m-0">
                <li>
                  <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <i
                      className="fa fa-angle-left"
                      aria-hidden="true"
                      style={{ cursor: "pointer", color: "#333" }}></i>
                  </button>
                </li>
                <li className="d-flex justify-center align-items-center">
                  {Array.from({ length: Math.ceil(total / perPage) }).map(
                    (_, index) => {
                      // Add logic to display ellipsis (...) if there are many pages
                      if (
                        index < 2 ||
                        index > Math.ceil(total / perPage) - 3 ||
                        (index >= currentPage - 2 && index <= currentPage + 1)
                      ) {
                        return (
                          <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={
                              currentPage === index + 1 ? "active-slide" : ""
                            }>
                            {index + 1}
                          </button>
                        );
                      } else if (
                        index === 2 ||
                        index === Math.ceil(total / perPage) - 3
                      ) {
                        // Display ellipsis (...) for the third and third-to-last position
                        return <span key={index}>...</span>;
                      }
                      return null;
                    }
                  )}
                </li>
                <li>
                  <button
                    disabled={currentPage === Math.ceil(total / perPage)}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <i
                      className="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BorrowerList;
