import React, { useEffect, useState } from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Header from "../../common/Header/Header";
import Loading from "../../common/Loading/Loading";
import Api from "../../services/Api";
import toast from "react-hot-toast";

function ShopNow() {
  const [isLoading, setIsLoading] = useState(false);
  const [occasions, setOccasions] = useState([]);
  const [styles, setStyles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [updateTimeout, setUpdateTimeout] = useState(null);

  const fetchShop = async () => {
    setIsLoading(true);
    try {
      const response = await Api.get_shop_data();
      const fetchedData = response?.data?.response;

      setOccasions(fetchedData.occasionList || []);
      setStyles(fetchedData.styleList || []);
      setBrands(fetchedData.brandList || []);

      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching shop data", error);
      setIsLoading(false);
    }
  };

  const updateShop = async (updatedData) => {
    try {
      await Api.update_shop_data(updatedData);
      toast.success("Successfully updated Shop Filters.");
    } catch (error) {
      console.log("Error updating shop data", error);
      toast.error("Failed to update banner. Please try again.");
    }
  };

  const handleCheckboxChange = (type, id, checked) => {
    let updatedOccasions = occasions;
    let updatedStyles = styles;
    let updatedBrands = brands;

    if (type === "occasion") {
      updatedOccasions = occasions.map((occasion) =>
        occasion.id === id
          ? { ...occasion, isDisplayOnShopNow: checked }
          : occasion
      );
      setOccasions(updatedOccasions);
    } else if (type === "style") {
      updatedStyles = styles.map((style) =>
        style.id === id ? { ...style, isDisplayOnShopNow: checked } : style
      );
      setStyles(updatedStyles);
    } else if (type === "brand") {
      updatedBrands = brands.map((brand) =>
        brand.id === id ? { ...brand, isDisplayOnShopNow: checked } : brand
      );
      setBrands(updatedBrands);
    }

    const updatedData = {};

    if (updatedOccasions.length > 0) {
      updatedData.ocasionDetails = updatedOccasions.map((occasion) => ({
        occasionId: occasion.id,
        display: occasion.isDisplayOnShopNow,
      }));
    }

    if (updatedStyles.length > 0) {
      updatedData.styleDetails = updatedStyles.map((style) => ({
        styleId: style.id,
        display: style.isDisplayOnShopNow,
      }));
    }

    if (updatedBrands.length > 0) {
      updatedData.brandDetails = updatedBrands.map((brand) => ({
        brandId: brand.id,
        display: brand.isDisplayOnShopNow,
      }));
    }

    // Ensure at least one detail array has elements before making the API call
    if (
      (updatedData.ocasionDetails && updatedData.ocasionDetails.length > 0) ||
      (updatedData.styleDetails && updatedData.styleDetails.length > 0) ||
      (updatedData.brandDetails && updatedData.brandDetails.length > 0)
    ) {
      // Clear any existing timeout to avoid multiple updates
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }

      // Set a new timeout to update shop data after 5 seconds
      const timeoutId = setTimeout(() => {
        updateShop(updatedData);
      }, 5000);

      setUpdateTimeout(timeoutId);
    } else {
      console.log("Error: Must provide at least one element in input slice");
    }
  };

  useEffect(() => {
    fetchShop();
  }, []);

  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-dashboard subadmin-dashboard">
        <Header title={"Shop Now Filters"} />
        {isLoading ? (
          <Loading />
        ) : (
          <div className="location-list-main mobile-list-main">
            <div className="shop-now-main">
              <div className="shop-category">
                <h3>Select Brand</h3>
                <ul>
                  {brands.map((brand) => (
                    <li key={brand.id}>
                      <input
                        type="checkbox"
                        checked={brand.isDisplayOnShopNow}
                        onChange={(e) =>
                          handleCheckboxChange(
                            "brand",
                            brand.id,
                            e.target.checked
                          )
                        }
                      />
                      {brand.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="shop-category">
                <h3>Select Occasion</h3>
                <ul>
                  {occasions.map((occasion) => (
                    <li key={occasion.id}>
                      <input
                        type="checkbox"
                        checked={occasion.isDisplayOnShopNow}
                        onChange={(e) =>
                          handleCheckboxChange(
                            "occasion",
                            occasion.id,
                            e.target.checked
                          )
                        }
                      />
                      {occasion.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="shop-category">
                <h3>Select Style</h3>
                <ul>
                  {styles.map((style) => (
                    <li key={style.id}>
                      <input
                        type="checkbox"
                        checked={style.isDisplayOnShopNow}
                        onChange={(e) =>
                          handleCheckboxChange(
                            "style",
                            style.id,
                            e.target.checked
                          )
                        }
                      />
                      {style.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShopNow;
