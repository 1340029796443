import React, { useState } from "react";
import logo from "../../asessts/Images/new_logo.svg";
import mail from "../../asessts/Images/mail_icon.svg";
import passwordIcon from "../../asessts/Images/password_icon.svg";
import login from "../../asessts/Images/login_img.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import action from "../../asessts/Images/action_eye.svg";
import actionOne from "../../asessts/Images/eye_close.svg";
import Api from "../../services/Api";
import { setLoginDetail } from "../../redux/slices/LoginSlice";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const loginDetail = useSelector((state) => state.adminSlice.loginDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(loginDetail);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (!password) {
      toast.error("Please enter password.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await Api.login(email, password);

      toast.success("Successfully logged in");
      if (response.status === 200) {
        const { id } = response?.data?.response;
        localStorage.setItem("creds", JSON.stringify({ email, id }));
        localStorage.setItem("fusionId", JSON.stringify(id));
        dispatch(setLoginDetail(response.data.response));
        navigate("/dashboard");
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <section id="Dashboard-login-main">
        <div class="dashboard-input-left">
          <div class="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div class="login-input-details">
            <h2>Login</h2>
            <p>Enter your details to login to your account</p>
            <div class="input-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="youremail@example.com"
              />
              <span class="icon">
                <img src={mail} alt="" />
              </span>
            </div>
            <div class="input-group mb">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <span class="icon">
                <img class="mobile-icon" src={passwordIcon} alt=" " />
              </span>
              <span class="eye-icon" onClick={togglePasswordVisibility}>
                {" "}
                <img src={showPassword ? action : actionOne} alt="" />{" "}
              </span>
            </div>
            {/*<div class="input-group">
              <Link>Forgot Password ?</Link>
            </div> */}
            <div class="login-btn">
              <button onClick={handleLogin} disabled={isLoading}>
                {isLoading ? <div className="upload_loader"></div> : "Login"}
              </button>
            </div>
          </div>
        </div>
        <div class="login-img-right">
          <img src={login} alt="" />
        </div>
      </section>
      <Toaster />
    </div>
  );
}

export default Login;
