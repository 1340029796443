import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "../slices/LoginSlice";
import currentPageSlice from "../slices/currentPageSlice";
import sideBarSlice from "../slices/sideBarSlice";

export const store = configureStore({
  reducer: {
    adminSlice,
    currentPageSlice,
    sideBarSlice,
  },
});
