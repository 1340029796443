import React, { useEffect, useState } from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Header from "../../common/Header/Header";
import editIcon from "../../asessts/Images/setting_edit_icon.svg";
import client from "../../services/Client";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Api from "../../services/Api";

import { setAdminData } from "../../redux/slices/LoginSlice";
import Loading from "../../common/Loading/Loading";
import ChangePasswordModal from "../../common/Modal/ChangePasswordModal";

function Setting() {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [picture, setPicture] = useState({
    url: "",
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPassLoading, setIsPassLoading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const storedCreds = JSON.parse(localStorage.getItem("creds")) || {};
  const [hasChanges, setHasChanges] = useState(false);

  const dispatch = useDispatch();
  const [editAdmin, setEditAdmin] = useState({
    email: "",
    firstName: "",
    lastName: "",
    profileImage: picture.url,
    currentPassword: "",
    newPassword: "",
  });
  useEffect(() => {
    if (isChangePasswordModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isChangePasswordModalOpen]);

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    const imageUrl = URL.createObjectURL(selectedFile);

    setPicture({
      url: imageUrl,
      file: selectedFile,
    });
    setHasChanges(true);
  };

  const fetchAdmin = async () => {
    setIsLoading(true);
    try {
      const response = await client.get(
        `/app/admin/settings/get-admin-details/${storedCreds.id}`
      );
      const adminData = response.data.admin;

      setEditAdmin(() => ({
        email: adminData?.email,
        firstName: adminData.firstName,
        lastName: adminData.lastName,
      }));

      const loadImage = adminData?.profileImage;

      if (loadImage) {
        setPicture({
          url: loadImage,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching admin", error);
    } finally {
      setIsLoading(false);
    }
  };
  console.log("picture", picture);
  useEffect(() => {
    fetchAdmin();
  }, []);

  const updateAdminData = async (e) => {
    e.preventDefault();
    try {
      setIsPassLoading(true);
      const formData = new FormData();

      for (const key in editAdmin) {
        formData.append(key, editAdmin[key]);
      }

      const profileImageUrl = picture.url
        ? picture.url
        : editAdmin.profileImage;

      console.log("----", profileImageUrl);

      if (picture.file != null) {
        formData.append("profileImage", picture.file);
      } else {
        formData.append("profileImage", profileImageUrl);
      }

      const updatedAdmin = {
        ...editAdmin,
        id: storedCreds.id,
        // profileImage: profileImageUrl,
      };

      const response = await Api.updateAdmin(storedCreds.id, formData);
      dispatch(setAdminData(response.admin));

      toast.success("Successfully updated admin data");
      setEditAdmin(updatedAdmin);
      // fetchAdmin()

      setIsPassLoading(false);
      setHasChanges(false);
    } catch (error) {
      console.error("Error updating admin data:", error);
      toast.error("Failed to update profile. Please try again.");
      setIsPassLoading(false);
    }
  };

  const handleChangePassword = async (passwordData) => {
    try {
      setChangeLoading(true);
      const response = await Api.changePassword(storedCreds.id, passwordData);
      console.log("Password Change Response:", response);
      setChangeLoading(false);
      toast.success("Password changed successfully");
      setIsChangePasswordModalOpen(false);
    } catch (error) {
      console.error("Error changing password:", error);
      setChangeLoading(false);
      toast.error("Failed to change password. Please try again.");
    }
  };

  const handleFirstNameChange = (e) => {
    const newFirstName = e.target.value;
    setEditAdmin({
      ...editAdmin,
      firstName: newFirstName,
    });
    setHasChanges(true);
  };

  const handleLastNameChange = (e) => {
    const newLastName = e.target.value;
    setEditAdmin({
      ...editAdmin,
      lastName: newLastName,
    });
    setHasChanges(true);
  };

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header title={"Settings"} />
        <div class="loaction-list-main">
          {isLoading ? (
            <Loading />
          ) : (
            <div class="setting-main">
              <div class="setting-details">
                <h3>Basic Details</h3>
                <p>
                  View & manage your basic profile details for the admin account
                </p>
                <div class="basic-detail">
                  <div class="profile-upload">
                    <div class="profile-img">
                      <div>
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={picture.url ? picture.url : picture}
                          alt="Profile"
                        />
                      </div>
                      <div class="input-file">
                        <input type="file" onChange={handleChange} />
                        <img src={editIcon} alt="" />
                      </div>
                    </div>
                    <label>Update Picture</label>
                  </div>
                  <div class="profile-details">
                    <div class="input-group">
                      <div class="input-field">
                        <label>First Name</label>
                        <input
                          type="text"
                          placeholder="Henry"
                          value={editAdmin.firstName}
                          onChange={handleFirstNameChange}
                        />
                      </div>
                      <div class="input-field">
                        <label>Last Name</label>
                        <input
                          type="text"
                          placeholder="Estward"
                          value={editAdmin.lastName}
                          onChange={handleLastNameChange}
                        />
                      </div>
                    </div>
                    <div class="input-group">
                      <div class="input-field">
                        <label>Email</label>
                        <input
                          type="email"
                          placeholder="Abcde@gmail.com"
                          value={editAdmin.email}
                          readOnly
                        />
                      </div>
                      <div class="input-field">
                        <label>Password</label>
                        <div class="password-change">
                          <input type="password" placeholder="Xxxxxxxxxxxxx" />
                          <button
                            onClick={() => setIsChangePasswordModalOpen(true)}>
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`save-btn ${hasChanges ? "" : "disabled"}`}
                  onClick={updateAdminData}>
                  <button disabled={!hasChanges || isPassLoading}>
                    {isPassLoading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isChangePasswordModalOpen && <div className="overlay"></div>}
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        onChangePassword={handleChangePassword}
        changeLoading={changeLoading}
      />
    </div>
  );
}

export default Setting;
