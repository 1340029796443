import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import error from "../../asessts/Images/warning_icon.svg";

Modal.setAppElement("#root");

const BlockModal = ({
  isOpen,
  onClose,
  SuccessMessage,
  ModalMessage,
  onConfirmBlock,
  isPassLoading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Location Added Successfully"
      className="modal-contentmodal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body">
        <img src={error} alt="" />
        <h2>{SuccessMessage}</h2>
        <p>{ModalMessage}</p>
        <button className="modal-close-btn" onClick={onClose}>
          No
        </button>
        <button
          disabled={isPassLoading}
          className="modal-confirm-btn ms-2"
          onClick={onConfirmBlock}>
          {onConfirmBlock ? "Confirm" : <div className="upload_loader"></div>}
        </button>
      </div>
    </Modal>
  );
};

export default BlockModal;
