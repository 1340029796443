import React, { useState, useEffect } from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Header from "../../common/Header/Header";
import action from "../../asessts/Images/arrow_left.svg";
import statIcon from "../../asessts/Images/stat_icon.svg";
import block from "../../asessts/Images/block_btn.svg";
import client from "../../services/Client";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loading from "../../common/Loading/Loading";
import toast from "react-hot-toast";
import BlockModal from "../../common/Modal/BlockModal";
import UnBlockModal from "../../common/Modal/UnBlockModal";
import { HiUserAdd } from "react-icons/hi";

function BorrowerDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [borrower, setBorrower] = useState({});
  const [isPassLoading, setIsPassLoading] = useState(false);
  const [userToBlock, setUserToBlock] = useState(null);
  const [userToUnBlock, setUserToUnBlock] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showUnBlockModal, setShowUnBlockModal] = useState(false);

  // console.log(showUnBlockModal);

  const navigate = useNavigate();

  const handleBlock = (userId) => {
    setUserToBlock(borrower);
    setShowBlockModal(true);
    setShowOverlay(true);
  };

  const handleUnBlock = (userId) => {
    setUserToUnBlock(borrower);
    setShowUnBlockModal(true);
    setShowOverlay(true);
  };

  const confirmBlock = async (userId) => {
    try {
      const payload = {
        blockUser: true,
      };
      await client.put(`/app/admin/lender/blockUser/${userId}`, payload);
      setShowBlockModal(false);
      setShowOverlay(false);
      setIsPassLoading(false);
      toast.success("Successfully blocked borrower,");
      navigate("/borrower");
    } catch (error) {
      setShowBlockModal(false);
      setShowOverlay(false);
      toast.error(error.response.data.message);
      console.error("Error Blocking user:", error);
    } finally {
      setIsPassLoading(false);
    }
  };

  const confirmUnBlock = async (userId) => {
    try {
      const payload = {
        blockUser: false,
      };
      await client.put(`/app/admin/lender/blockUser/${userId}`, payload);
      setShowBlockModal(false);
      setShowOverlay(false);
      setIsPassLoading(false);
      toast.success("Successfully un-blocked lender");
      navigate("/borrower");
    } catch (error) {
      setShowBlockModal(false);
      setShowOverlay(false);
      toast.error(error.response.data.message);
      console.error("Error Blocking user:", error);
    } finally {
      setIsPassLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowBlockModal(false);
    setShowUnBlockModal(false);
    setShowOverlay(false);
  };

  const fetchVideoDetails = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(
        `/app/admin/borrower/borrowerById/${id}`
      );
      //   console.log("borrower", response);
      setBorrower(response.data.response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching video details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoDetails();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const getInitials = (name) => {
    if (name) {
      const nameArray = name.split(" ");
      return nameArray
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase();
    }
    return "";
  };

  console.log("****", borrower);

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header imageUrl={action} title={"Borrower Details"} />
        <div class="loaction-list-main">
          {isLoading ? (
            <Loading />
          ) : (
            <div class="setting-main borrow-main">
              <div class="setting-details">
                <div class="basic-detail">
                  <div class="profile-upload">
                    <div class="profile-img">
                      {borrower.profileImage !== "" ? (
                        <img src={borrower.profileImage} alt="agent" />
                      ) : (
                        <div className="placeholder-name">
                          {getInitials(borrower.name)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="profile-details">
                    <div class="borrow-head">
                      <div class="left">
                        <h2>
                          {borrower.name}{" "}
                          <span>
                            <img src={statIcon} alt="" />
                            {borrower?.ratings?.toFixed(1)}
                          </span>
                        </h2>
                        <p>
                          <b>Added on:</b> {formatDate(borrower.createdAt)}
                        </p>
                      </div>
                      <div class="right">
                        {borrower?.isBlocked === true ? (
                          <button
                            style={{ border: "1px solid green" }}
                            onClick={() => handleUnBlock(borrower?.id)}>
                            <HiUserAdd
                              style={{ color: "green", fontSize: "24px" }}
                            />
                          </button>
                        ) : (
                          <button onClick={() => handleBlock(borrower?.id)}>
                            <img src={block} alt="block" />
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="input-group">
                      <div class="input-field">
                        <label>Name</label>
                        <input type="text" value={borrower.name} />
                      </div>
                      <div class="input-field">
                        <label>Email</label>
                        <input type="email" value={borrower.email} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showOverlay && <div className="overlay"></div>}
      <BlockModal
        isOpen={showBlockModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to Block?"
        onConfirmBlock={() => confirmBlock(userToBlock.id)}
        changeLoading={isPassLoading}
      />
      <UnBlockModal
        isOpen={showUnBlockModal}
        onClose={cancelDelete}
        SuccessMessage="Please confirm!"
        ModalMessage="Are you sure you want to Un-Block?"
        onConfirmBlock={() => confirmUnBlock(userToUnBlock?.id)}
        changeLoading={isPassLoading}
      />
    </div>
  );
}

export default BorrowerDetail;
