import React, { useEffect, useState } from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Header from "../../common/Header/Header";
import lender_icon from "../../asessts/Images/lender_icon.svg";
import borrowers_icon from "../../asessts/Images/borrowers_icon.svg";
import items_icon from "../../asessts/Images/items_listed_icon.svg";
import items_borrow_icon from "../../asessts/Images/items_borrow_icon.svg";
import listed_icon from "../../asessts/Images/listed_icon.svg";
import borrow_item_icon from "../../asessts/Images/borrow_item_icon.svg";
import refund_icon from "../../asessts/Images/refund_icon.svg";
import dispute_icon from "../../asessts/Images/dispute_icon.svg";
import chart from "../../asessts/Images/chart_img.png";
import ReactApexChart from "react-apexcharts";
import client from "../../services/Client";
import Loading from "../../common/Loading/Loading";
import { Link } from "react-router-dom";

function Dashboard() {
  const [earning, setEarning] = useState([]);
  const [order, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [counts, setCounts] = useState({});
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedYearOne, setSelectedYearOne] = useState("");
  const years = Array.from({ length: 2 }, (_, index) => currentYear - index);

  console.log("counts", counts);

  useEffect(() => {
    setSelectedYear(new Date().getFullYear().toString());
    setSelectedYearOne(new Date().getFullYear().toString());
  }, []);

  const fetchRecord = async () => {
    try {
      setCustomerLoading(true);
      if (selectedYear) {
        const url = `/app/admin/dashboard/monthlyRecordsByYear/${selectedYear}`;
        const response = await client.get(url);
        const monthlyEarnings = response?.data?.newUsers;
        setEarning(monthlyEarnings);
      }
      setCustomerLoading(false);
    } catch (error) {
      setCustomerLoading(false);
      console.log("Error while fetching monthly records", error);
    }
  };

  const fetchOrderRecord = async () => {
    try {
      setOrderLoading(true);
      if (selectedYearOne) {
        const url = `/app/admin/dashboard/monthlyOrdersByYear/${selectedYearOne}`;
        const response = await client.get(url);
        const monthlyEarnings = response?.data?.monthlyOrders;
        setOrder(monthlyEarnings);
      }
      setOrderLoading(false);
    } catch (error) {
      setOrderLoading(false);
      console.log("Error while fetching monthly records", error);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const url = `/app/admin/dashboard/getReports`;
      const response = await client.get(url);
      const data = response?.data?.response;
      setCounts(data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while fetching monthly records", error);
    }
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
    fetchRecord(selectedYear);
  };

  const handleYearChangeOne = (event) => {
    const selectedYearOne = event.target.value;
    setSelectedYearOne(selectedYearOne);
    fetchOrderRecord(selectedYearOne);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchRecord();
  }, [selectedYear]);

  useEffect(() => {
    fetchOrderRecord();
  }, [selectedYearOne]);

  const series = [
    {
      name: "Users",
      data: earning.map((entry) => ({
        x: entry.month,
        y: parseInt(entry.users),
      })),
    },
  ];

  const seriesOne = [
    {
      name: "Orders",
      data: order.map((entry) => ({
        x: entry.month,
        y: parseInt(entry.orders),
      })),
    },
  ];

  const [options] = useState({
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: earning.users?.map((entry) => entry.month) || [],
    },
    legend: {
      horizontalAlign: "left",
    },
  });

  const [optionsOne] = useState({
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: earning.orders?.map((entry) => entry.month) || [],
    },
    legend: {
      horizontalAlign: "left",
    },
    colors: ["#91fc9c"],
  });

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header title={"Dashboard"} />
        <div class="dashboard-main" style={{ padding: 0 }}>
          {isLoading ? (
            <Loading />
          ) : (
            <div class="dashboard-left">
              <div class="dashboard-main-details">
                <div
                  class="dashboard-details-left"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <div class="dashboard-main-col">
                    <div class="dashboard-details-col">
                      <div class="dashboard-details-heading p-spacing">
                        <div class="main-heading">
                          <span>Total Lenders</span>
                          <Link to={"/lenders"}>
                            <h3 style={{ color: "#000" }}>
                              {counts?.totalLenders || 0}
                            </h3>
                          </Link>
                        </div>
                        <div class="details-img">
                          <span>
                            <img src={lender_icon} alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="dashboard-details-col">
                      <div class="dashboard-details-heading p-spacing">
                        <div className="main-heading">
                          <span>Total Borrowers</span>
                          <Link to={"/borrower"}>
                            <h3 style={{ color: "#000" }}>
                              {counts?.totalBorrower || 0}
                            </h3>
                          </Link>
                        </div>
                        <div class="details-img">
                          <span>
                            <img src={borrowers_icon} alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="dashboard-details-col">
                      <div class="dashboard-details-heading">
                        <div class="main-heading">
                          <span>Recently Listed Items</span>
                          <h3>{counts?.recentlyListedItmes || 0}</h3>
                        </div>
                        <div class="details-img">
                          <span>
                            <img src={items_icon} alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="dashboard-details-col">
                      <div class="dashboard-details-heading">
                        <div class="main-heading">
                          <span>Recently Borrowed Items</span>
                          <h3>{counts?.recentlyBorrowedItems || 0}</h3>
                        </div>
                        <div class="details-img">
                          <span>
                            <img src={items_borrow_icon} alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dashboard-details-left">
                    <div class="dashboard-main-col">
                      <div class="dashboard-details-col">
                        <div class="dashboard-details-heading p-spacing">
                          <div class="main-heading">
                            <span>Total Item Listed</span>
                            <h3>{counts?.totalListedItems || 0}</h3>
                          </div>
                          <div class="details-img">
                            <span>
                              <img src={listed_icon} alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="dashboard-details-col">
                        <div class="dashboard-details-heading p-spacing">
                          <div class="main-heading">
                            <span>Total Item Borrowed</span>
                            <h3>{counts?.totalBorrowedItems || 0}</h3>
                          </div>
                          <div class="details-img">
                            <span>
                              <img src={borrow_item_icon} alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="dashboard-details-col">
                        <div class="dashboard-details-heading">
                          <div class="main-heading">
                            <span>Total Refund</span>
                            <h3>€{counts?.totalRefunds || 0}</h3>
                          </div>
                          <div class="details-img">
                            <span>
                              <img src={refund_icon} alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="dashboard-details-col">
                        <div class="dashboard-details-heading">
                          <div class="main-heading">
                            <span>Total Disputes</span>
                            <h3>{counts?.totalDisputes || 0}</h3>
                          </div>
                          <div class="details-img">
                            <span>
                              <img src={dispute_icon} alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-main-details dashboard-main-details-second">
                <div
                  class="dashboard-details-right"
                  style={{ position: "relative" }}
                >
                  {orderLoading ? (
                    <div>
                      <Loading />
                    </div>
                  ) : (
                    <div class="dashboard-graph-main order-graph-main">
                      <div class="graph-head">
                        <h3>Completed Orders</h3>
                        <select
                          style={{
                            height: "30px",
                            border: "1px solid rgb(220, 218, 218)",
                            textIndent: "0px",
                          }}
                          value={selectedYearOne}
                          onChange={handleYearChangeOne}
                          placeholder="Select a year"
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="graph-container">
                        <ReactApexChart
                          options={optionsOne}
                          series={seriesOne}
                          type="area"
                          height={250}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="dashboard-details-right"
                  style={{ position: "relative" }}
                >
                  {customerLoading ? (
                    <div>
                      <Loading />
                    </div>
                  ) : (
                    <div class="dashboard-graph-main">
                      <div class="graph-head">
                        <h3>New Customer</h3>
                        <select
                          style={{
                            height: "30px",
                            border: "1px solid rgb(220, 218, 218)",
                            textIndent: "0px",
                          }}
                          value={selectedYear}
                          onChange={handleYearChange}
                          placeholder="Select a year"
                        >
                          ss
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="graph-container">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="area"
                          height={250}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div class="dashboard-order-details">
                <div class="dashboard-orders"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
