// redux/slices/LogoutSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const logoutSlice = createSlice({
  name: "logout",
  initialState: {
    isLoggedIn: false,
  },
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const { logout } = logoutSlice.actions;

export default logoutSlice.reducer;
