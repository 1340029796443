import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./Modal.css";
import { useSelector } from "react-redux";
import eyeSlashIcon from "../../asessts/Images/action_eye.svg";
import eyeIcon from "../../asessts/Images/eye_close.svg";
import CloseIcon from "../../asessts/Images/times_icon.svg";
import { toast } from "react-toastify";

Modal.setAppElement("#root");

const ChangePasswordModal = ({
  isOpen,
  onClose,
  onChangePassword,
  changeLoading,
}) => {
  const adminDataOne = useSelector((state) => state.adminSlice.adminData);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibilityPassword = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityNew = () => {
    setShowNewPassword(!showNewPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (isOpen) {
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  }, [isOpen, adminDataOne]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordData.currentPassword) {
      toast.error("Current Password is required");
      return;
    }
    if (!passwordData.newPassword) {
      toast.error("New password is required");
      return;
    }
    if (!passwordData.confirmPassword) {
      toast.error("Confirm Password is required");
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast.error("New password and Confirm Password should be same");
      return;
    }

    const passwordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordFormat.test(passwordData.newPassword)) {
      toast.error(
        "Password must contain uppercase, lowercase,special character and number"
      );
      return;
    }

    onChangePassword(passwordData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Change Password"
      className="change_password-modal"
      overlayClassName="modal-overlay">
      <form onSubmit={handleSubmit} className="modal-body">
        <div style={{ textAlign: "left", display:"flex", justifyContent:"space-between" }}>
          <h5>Change Password</h5>
          <button style={{backgroundColor:"transparent", border:"none"}}><img src={CloseIcon} /></button>
        </div>
        <div style={{ marginTop: "35px" }}>
          <label htmlFor="currentPassword">Current Password</label>
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              name="currentPassword"
              value={passwordData.currentPassword}
              onChange={handleChange}
            />
            <span
              class="icon"
              onClick={togglePasswordVisibilityPassword}
              style={{ position: "absolute", top: "17px", right: "15px" }}>
              <img
                class="mobile-icon"
                src={showPassword ? eyeSlashIcon : eyeIcon}
                alt=""
              />
            </span>
          </div>
        </div>
        <div>
          <label htmlFor="newPassword">New Password</label>
          <div style={{ position: "relative" }}>
            <input
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              value={passwordData.newPassword}
              onChange={handleChange}
            />

            <span
              class="icon"
              onClick={togglePasswordVisibilityNew}
              style={{ position: "absolute", top: "17px", right: "15px" }}>
              <img
                class="mobile-icon"
                src={showNewPassword ? eyeSlashIcon : eyeIcon}
                alt=""
              />
            </span>
          </div>
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <div style={{ position: "relative" }}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={handleChange}
            />

            <span
              class="icon"
              onClick={togglePasswordVisibilityConfirm}
              style={{ position: "absolute", top: "17px", right: "15px" }}>
              <img
                class="mobile-icon"
                src={showConfirmPassword ? eyeSlashIcon : eyeIcon}
                alt=""
              />
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <button className="modal-not-btn" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            className="modal-change-btn ms-2"
            disabled={changeLoading}>
            {changeLoading ? <div className="upload_loader"></div> : "Change"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
