import React from "react";
import logo from "../../asessts/Images/dashboard_new_logo.svg";
import bar from "../../asessts/Images/bar_icon.svg";
import times from "../../asessts/Images/times_icon.svg";
import order from "../../asessts/Images/order_icon.svg";
import dashboard from "../../asessts/Images/dashboard_icon.svg";
import dispute from "../../asessts/Images/disputed_icon.svg";
import lender from "../../asessts/Images/lendor_dashboard_icon.svg";
import borrow from "../../asessts/Images/borrow_icon.svg";
import setting from "../../asessts/Images/setting_icon.svg";
import shopNow from "../../asessts/Images/shop-now.svg";
import mobile from "../../asessts/Images/mobile_home_icon.svg";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSideBar } from "../../redux/slices/sideBarSlice";

function Sidebar() {
  const sideBarSlice = useSelector((state) => state.sideBarSlice.sideBar);
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    const newSidebarState = !sideBarSlice;
    dispatch(setSideBar(newSidebarState));
  };
  return (
    <div className={`dashboard-menu ${sideBarSlice ? "dashboard-active" : ""}`}>
      <div class="logo">
        <div class="logo-img">
          <a href="Dashboard.html">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div class="tab-menu-bar">
          <button className="barBtn" onClick={toggleSidebar}>
            <img src={sideBarSlice ? times : bar} alt="" />
          </button>
        </div>
      </div>
      <div class="dashboard-options">
        <ul className="p-0">
          <li>
            <NavLink exact class="active" to="/dashboard">
              <span class="menu-icon">
                <img src={dashboard} alt="dashboard icon" />
              </span>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to={"/order"}>
              <span class="menu-icon">
                <img src={order} alt="discount_icon" />
              </span>
              <span>Orders</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/disputed-order">
              <span class="menu-icon">
                <img src={dispute} alt="vendor_icon" />
              </span>
              <span>Disputed Orders</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/lenders">
              <span class="menu-icon">
                <img src={lender} alt="dashboard icon" />
              </span>
              <span>Lenders</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/borrower">
              <span class="menu-icon">
                <img src={borrow} alt="discount_icon" />
              </span>
              <span>Borrowers</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/mobile-home">
              <span class="menu-icon">
                <img src={mobile} alt="mobile home icon" />
              </span>
              <span>Mobile Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/shop-now">
              <span class="menu-icon">
                <img src={shopNow} alt="setting icon" />
              </span>
              <span>Shop Now (Filters)</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/setting">
              <span class="menu-icon">
                <img src={setting} alt="setting icon" />
              </span>
              <span>Settings</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
