import React from "react";
import logo from "../../asessts/Images/logo.png";
import arrow from "../../asessts/Images/left_arrow.svg";
import login from "../../asessts/Images/login_img.png";
import { Link } from "react-router-dom";

function VerifyOtp() {
  return (
    <div class="for-overlay">
      <section id="Dashboard-login-main">
        <div class="dashboard-input-left">
          <div class="login-back-btn">
            <Link to={"/"}>
              <img src={arrow} alt="" />
            </Link>
          </div>
          <div class="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div class="login-input-details">
            <h2>Verifying Email</h2>
            <p>Please enter the OTP sent on your email</p>
            <div class="otp-input">
              <input
                type="text"
                value="5"
                id="digit1"
                maxlength="1"
                pattern="[0-9]"
                required
              />
              <input
                type="text"
                value="8"
                id="digit2"
                maxlength="1"
                pattern="[0-9]"
                required
              />
              <input
                type="text"
                value="3"
                id="digit3"
                maxlength="1"
                pattern="[0-9]"
                required
              />
              <input
                type="text"
                value="2"
                id="digit4"
                maxlength="1"
                pattern="[0-9]"
                required
              />
              <input
                type="text"
                value="6"
                id="digit5"
                maxlength="1"
                pattern="[0-9]"
                required
              />
              <input
                type="text"
                value="7"
                id="digit6"
                maxlength="1"
                pattern="[0-9]"
                required
              />
            </div>
            <div class="login-btn">
              <button>
                <Link to={"/dashboard"}>Verify Otp</Link>
              </button>
            </div>
            <div class="resend-otp">
              <p>
                Didn't receive the email? <a href="#">Click to resend</a>
              </p>
            </div>
          </div>
        </div>
        <div class="login-img-right">
          <img src={login} alt="" />
        </div>
      </section>

      <div class="overlay"></div>
    </div>
  );
}

export default VerifyOtp;
