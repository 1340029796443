import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./Modal.css";
import error from "../../asessts/Images/pay.png";
import toast from "react-hot-toast";

Modal.setAppElement("#root");

const PayModal = ({
  isOpen,
  onClose,
  onChangePassword,
  changeLoading,
  id,
  paymentId,
  lenderId,
  amount,
}) => {
  const [returnData, setReturnData] = useState({
    id: id,
    paymentId: paymentId,
    lenderId: lenderId,
    reason: "",
    amount: amount,
  });

  useEffect(() => {
    if (isOpen) {
      setReturnData({
        id: id,
        paymentId: paymentId,
        lenderId: lenderId,
        reason: "",
        amount: amount,
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReturnData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (returnData.reason === "") {
      console.log("clicked");
      toast.error("Reason for refund is required.");
      return;
    }
    onChangePassword(returnData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Change Password"
      className="pay_password-modal"
      overlayClassName="modal-overlay">
      <div className="modal-body mt-0">
        <img src={error} alt="" />
        <form onSubmit={handleSubmit} className="modal-body">
          <div style={{ textAlign: "left" }}>
            <h2 className="text-center">Please Confirm!</h2>
            <p className="text-center">
              Are you sure, you want to send refund to borrower?
            </p>
          </div>
          <div style={{ marginTop: "35px" }}>
            <div style={{ position: "relative" }}>
              <textarea
                cols={5}
                rows={4}
                name="reason"
                value={returnData.reason}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <button className="modal-not-btn" onClick={onClose}>
              Cancel
            </button>
            <button
              type="submit"
              className="modal-change-btn ms-2"
              disabled={changeLoading}>
              {changeLoading ? <div className="upload_loader"></div> : "Refund"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PayModal;
