import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import action from "../../asessts/Images/arrow_left.svg";
import TrackIcon from "../../asessts/Images/track_right_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../services/Api";
import Loading from "../../common/Loading/Loading";
import PayModal from "../../common/Modal/PayModal";
import toast from "react-hot-toast";

function DisputeDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [changeLoading, setChangeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [dispatchedPhotos, setDispatchedPhotos] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [trackNumber, setTrackNumber] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [lenderDetails, setLenderDetails] = useState({});
  const [borrowerDetails, setBorrowerDetails] = useState({});
  const [amount, setAmount] = useState(0);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const formatStatus = (status) => {
    return status
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const response = await Api.get_dispute_order(id);
      const responseData = response?.data?.response || {};

      console.log("response data", response);

      const trackOrderListing =
        response?.data?.response?.trackOrderListing || [];
      setOrderDetails(trackOrderListing);
      const lastItem = trackOrderListing[trackOrderListing.length - 1];
      const status = lastItem?.status;

      switch (status) {
        case "dispached-by-lender":
          setDispatchedPhotos(
            lastItem?.dispatchedByLenderDetail?.dispatchPhotos || []
          );
          setOrderNumber(lastItem?.dispatchedByLenderDetail?.orderId);
          setTrackingUrl(lastItem?.dispatchedByLenderDetail?.trackUrl);
          setTrackNumber(lastItem?.dispatchedByLenderDetail?.trackNumber);
          setResponseMessage(
            lastItem?.dispatchedByLenderDetail?.reasonOfDispute
          );
          break;
        case "dispute-by-borrower":
          setDispatchedPhotos(
            lastItem?.disputeByBorrowerDetail?.disputedPhotos || []
          );
          setOrderNumber(lastItem?.disputeByBorrowerDetail?.orderId);
          setTrackingUrl(lastItem?.disputeByBorrowerDetail?.trackUrl);
          setTrackNumber(lastItem?.disputeByBorrowerDetail?.trackNumber);
          setResponseMessage(
            lastItem?.disputeByBorrowerDetail?.reasonOfDispute
          );
          break;
        case "In Transit":
          setDispatchedPhotos(
            lastItem?.intransiteByLender?.intransiteImages || []
          );
          setOrderNumber(lastItem?.intransiteByLender?.orderId);
          setTrackingUrl(lastItem?.intransiteByLender?.trackUrl);
          setTrackNumber(lastItem?.intransiteByLender?.trackNumber);
          setResponseMessage(lastItem?.intransiteByLender?.reasonOfDispute);
          break;
        case "dispute-by-lender":
          setDispatchedPhotos(
            lastItem?.disputeByLenderDetail?.disputedPhotos || []
          );
          setOrderNumber(lastItem?.disputeByLenderDetail?.orderId);
          setTrackingUrl(lastItem?.disputeByLenderDetail?.trackUrl);
          setTrackNumber(lastItem?.disputeByLenderDetail?.trackNumber);
          setResponseMessage(
            lastItem?.dispatchedByLenderDetail?.reasonOfDispute
          );
          break;
        case "refund-by-admin":
          const disputeByLenderDetail = trackOrderListing.find(
            (item) => item.status === "dispute-by-lender"
          )?.disputeByLenderDetail;
          const disputeByBorrowerDetail = trackOrderListing.find(
            (item) => item.status === "dispute-by-borrower"
          )?.disputeByBorrowerDetail;

          if (disputeByLenderDetail) {
            setDispatchedPhotos(disputeByLenderDetail.disputedPhotos || []);
            setOrderNumber(disputeByLenderDetail.orderId);
            setTrackingUrl(disputeByLenderDetail.trackUrl);
            setTrackNumber(disputeByLenderDetail.trackNumber);
            setResponseMessage(disputeByLenderDetail.reasonOfDispute);
          } else if (disputeByBorrowerDetail) {
            setDispatchedPhotos(disputeByBorrowerDetail.disputedPhotos || []);
            setOrderNumber(disputeByBorrowerDetail.orderId);
            setTrackingUrl(disputeByBorrowerDetail.trackUrl);
            setTrackNumber(disputeByBorrowerDetail.trackNumber);
            setResponseMessage(disputeByBorrowerDetail.reasonOfDispute);
          } else {
            // If there are no dispute details found, reset values
            setDispatchedPhotos([]);
            setOrderNumber("");
            setTrackingUrl("");
            setTrackNumber("");
            setResponseMessage("");
          }
          break;

        default:
          setDispatchedPhotos([]);
          break;
      }

      setLenderDetails(responseData.lenderDetails || {});
      setBorrowerDetails(responseData.borrowerDetails || {});
      setAmount(responseData.amount);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Set to true for 12-hour format with AM/PM
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleChangePassword = async (returnData) => {
    try {
      setChangeLoading(true);
      const response = await Api.payBorrower(returnData);
      console.log("Password Change Response:", response);
      setChangeLoading(false);
      navigate("/disputed-order");
      toast.success("Successfully refund to borrower.");
      setIsChangePasswordModalOpen(false);
    } catch (error) {
      console.error("Error refund to borrower.:", error);
      setChangeLoading(false);
      toast.error("Failed to refund to borrower. Please try again.");
    }
  };

  return (
    <div class="admin-dashboard">
      <Sidebar />
      <div class="main-dashboard subadmin-dashboard">
        <Header imageUrl={action} title={`Order Details`} />
        <div class="loaction-list-main order-list-main">
          {isLoading ? (
            <Loading />
          ) : (
            <div class="order-tracking-main">
              <div class="tracking-left">
                <div class="head">
                  <h3>Order Tracking Details</h3>
                </div>
                <div class="details">
                  <div class="input-group">
                    <div class="input-field">
                      <label for="">Order ID</label>
                      <input
                        type="text"
                        value={orderNumber}
                        placeholder={"#542365"}
                      />
                    </div>
                    
                    <div class="input-field">
                      <label for="">Tracking Number</label>
                      <input
                        type="text"
                        value={trackNumber}
                        placeholder="#542365"
                      />
                    </div>
                    <div class="input-field">
                      <label for="">Tracking URL</label>
                      <input
                        type="text"
                        value={trackingUrl}
                        placeholder="#542365"
                      />
                    </div>
                  </div>
                  {responseMessage && (
                    <div class="input-group">
                      <div class="input-field reason">
                        <label for="">Reason for Dispute</label>
                        <textarea
                          placeholder="#542365"
                          value={responseMessage}></textarea>
                      </div>
                    </div>
                  )}
                  {dispatchedPhotos.length > 0 && (
                    <div class="order-photos">
                      <label for="">Product Dispatched Photos</label>
                      <div class="img">
                        {dispatchedPhotos.map((photo, index) => (
                          <span key={index}>
                            <img
                              src={photo}
                              className="py-3"
                              alt={`Dispatched ${index}`}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div class="tracking-right">
                <h3>Order Tracking Details</h3>
                <div className="return-back">
                  <div className="head">
                    <h4>
                      {orderDetails.length > 0
                        ? formatStatus(
                            orderDetails[orderDetails.length - 1].status
                          )
                        : ""}
                    </h4>
                  </div>
                  <div className="track-path">
                    <div className="order-track-path">
                      {orderDetails.map((order, index) => (
                        <div className="key-chain" key={index}>
                          <div className="key-chain-img">
                            <span>
                              <img src={TrackIcon} alt="" />
                            </span>
                          </div>
                          <div className="key-chain-head">
                            <h3>{formatStatus(order.status)}</h3>
                            <p>{formatDate(order.createdAt)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div class="lendor-info">
                  <h3>Lender’s Contact Info</h3>
                  <div class="info-detail">
                    <ul className="p-0">
                      <li>
                        Name <span>{lenderDetails.name}</span>
                      </li>
                      <li>
                        Phone Number <span>{lenderDetails.phoneNumber}</span>
                      </li>
                      <li>
                        Email <span>{lenderDetails.email}</span>
                      </li>
                    </ul>
                  </div>
                  {/* <button class="send-btn">Send Payment to lender</button> */}
                </div>
                <div class="lendor-info">
                  <h3>Borrower’s Contact Info</h3>
                  <div class="info-detail">
                    <ul className="p-0">
                      <li>
                        Name <span>{borrowerDetails.name}</span>
                      </li>
                      <li>
                        Phone Number <span>{borrowerDetails.phoneNumber}</span>
                      </li>
                      <li>
                        Email <span>{borrowerDetails.email}</span>
                      </li>
                    </ul>
                  </div>
                  <button
                    onClick={() => setIsChangePasswordModalOpen(true)}
                    class="send-btn">
                    Refund to borrower
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isChangePasswordModalOpen && <div className="overlay"></div>}
      <PayModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        onChangePassword={handleChangePassword}
        changeLoading={changeLoading}
        id={id}
        paymentId={borrowerDetails.paymentIntentId}
        lenderId={lenderDetails.id}
        amount={amount}
      />
    </div>
  );
}

export default DisputeDetail;
