import axios from "axios";
// https://5drz7jg9-5050.inc1.devtunnels.ms/
const API_BASE_URL = process.env.REACT_APP_TOGGA_FASHION_API_BASE_URL;
// const API_BASE_URL = "https://5drz7jg9-5050.inc1.devtunnels.ms";
console.log(API_BASE_URL);
const token = localStorage.getItem("togaToken");

const client = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default client;
