import client from "./Client";

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      handleTokenExpiration();
    }
    return Promise.reject(error);
  }
);

function handleTokenExpiration() {
  clearToken();
  window.location.href = "/";
}

function clearToken() {
  localStorage.removeItem("jwt_token");
}

const Api = {
  login: (email, password) =>
    client.post(`/app/admin/adminLogin`, { email, password }),
  verifyOTP: (email, otp) =>
    client.post(`/app/admin/verifyOtp`, { email, otp }),

  get_admin_data: (id) => client.get(`/admin/get-admin-details/${id}`),

  get_complete_order: (id) =>
    client.get(`/app/admin/orders/GetCompletedOrdersDetailById/${id}`),
  get_dispute_order: (id) =>
    client.get(`/app/admin/dispute/disputedOrdersById/${id}`),

  get_track_order: (id) =>
    client.get(`/app/admin/orders/trackOrderListingById/${id}`),

  get_occasion_data: () => client.get(`/app/admin/occasionListing`),

  get_shop_data: () => client.get(`/app/admin/shopNow/getFilterList`),

  update_shop_data: async (updatedData) => {
    console.log("api data", updatedData);
    return await client.put("/app/admin/shopNow/updateFilterList", updatedData);
  },

  get_order_data: () => client.get(`/app/admin/orders/getOrderRequests`),

  get_track_data: () => client.get(`/app/admin/orders/trackOrderListing`),

  get_complete_data: () => client.get(`/app/admin/orders/getCompletedOrders`),

  get_disputed_data: () => client.get(`/app/admin/dispute/disputedOrderList`),

  update_occasion_data: (data) =>
    client.post(`/app/admin/changeOcasionDisplay`, data),

  updateAdmin: async (id, formData) => {
    try {
      const res = await client.put(
        `/app/admin/settings/update-admin/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  },
  changePassword: async (id, passwordData) => {
    console.log("passwordData", passwordData);
    try {
      const response = await client.put(
        `/app/admin/settings/update-password/${id}`,
        passwordData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  payBorrower: async (returnData) => {
    console.log("passwordData", returnData);
    try {
      const response = await client.put(
        `/app/admin/dispute/refundToborrower`,
        returnData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateBanner: async (formData) => {
    try {
      const res = await client.post(`/app/admin/addBanner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  },
};

export default Api;
