import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminData: null,
  loginDetail: null,
};

const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {
    setAdminData: (state, { payload }) => {
      state.adminData = payload;
    },
    setLoginDetail: (state, { payload }) => {
      state.loginDetail = payload;
    },
  },
});

export const { setAdminData, setLoginDetail } = adminSlice.actions;
export default adminSlice.reducer;
